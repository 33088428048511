<template>
  <div class="clearfix">
    <vs-input
      v-validate="'required|min:3'"
      data-vv-validate-on="blur"
      label="Name"
      name="displayName"
      placeholder="Name"
      v-model="displayName"
      class="w-full" />
    <span class="text-danger text-sm">{{ errors.first('displayName') }}</span>

    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      label="Email"
      placeholder="Email"
      v-model="email"
      class="w-full mt-4" />
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-select
      name="university"
      label="Your University"
      placeholder="University"
      v-model="university"
      class="w-full mt-4" >
      <vs-select-item :key="index" :value="item.university_id" :text="item.univ_name" v-for="(item, index) in this.universityList"/>
    </vs-select>
    <span class="text-danger text-sm">{{ errors.first('university') }}</span>

    <vs-select
      name="studyArea"
      label="Your Study Area"
      placeholder="Study Area"
      v-model="studyArea"
      class="w-full mt-4"
      >
      <vs-select-item :key="index" :value="item.stu_area_id" :text="item.study_area" v-for="(item, index) in this.studyAreaList" />
    </vs-select>
    <span class="text-danger text-sm">{{ errors.first('studyArea') }}</span>

    <vs-select
      name="course"
      label="Course Studying or Studied"
      placeholder="Please select university and study area"
      v-model="course"
      class="w-full mt-4"
      >
      <vs-select-item :key="index" :value="item.course_id" :text="item.course_name" v-for="(item, index) in this.courseList" />
    </vs-select>
    <span class="text-danger text-sm">{{ errors.first('course') }}</span>

    <vs-select
      name="studyStatus"
      v-model="studyStatus"
      label="Currently studying or alumni ?"
      placeholder="Course Studying or Studied"
      class="w-full mt-4"
      >
      <vs-select-item :key="index" :value="item.value" :text="item.value" v-for="(item, index) in this.studyStatusSelect" />
    </vs-select>
    <span class="text-danger text-sm">{{ errors.first('studyStatus') }}</span>

    <vs-select
      name="interestedChannel"
      multiple
      label="Channels interested to follow/be part of community"
      placeholder="Interested Channels"
      v-model="interestedChannels"
      class="w-full mt-4"
      >
      <vs-select-item :key="index" :value="item.channel_id" :text="item.channel_name" v-for="(item, index) in this.interestedChannelList" />
    </vs-select>
    <span class="text-danger text-sm">{{ errors.first('interestedChannel') }}</span>

    <vs-input
      ref="password"
      type="password"
      data-vv-validate-on="blur"
      v-validate="'required|min:6|max:32'"
      name="password"
      label="Password"
      placeholder="Password"
      v-model="password"
      class="w-full mt-4" />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <vs-input
      type="password"
      v-validate="'min:6|max:32|confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirm_password"
      class="w-full mt-4" />
    <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>

    <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">I accept the terms & conditions.</vs-checkbox>
    <vs-button  type="border" to="/login" class="mt-6">Login</vs-button>
    <vs-button class="float-right mt-6 mb-8" @click="registerUser" :disabled="!validateForm">Register</vs-button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      displayName: '',
      email: '',
      university: '',
      studyArea: '',
      course: '',
      studyStatus: '',
      password: '',
      confirm_password: '',
      isTermsConditionAccepted: true,
      universityList: [],
      studyAreaList: [],
      courseList: [],
      studyStatusSelect: [
        { value: 'Student' },
        { value: 'Alumni' },
        { value: 'Future Student' }
      ],
      interestedChannelList: []
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.displayName !== '' && this.email !== '' && this.password !== '' && this.confirm_password !== '' && this.isTermsConditionAccepted === true
    }
  },
  created () {
    axios.post(`${process.env.VUE_APP_BASE_URL  }/getinstitutelist`)
      .then(res => {
        this.universityList = res.data.data
      })
      .catch(err => this.$store.commit('SET_ERROR', err, { root: true }))
    axios.post(`${process.env.VUE_APP_BASE_URL  }/getAllChannels`)
      .then(res => {
        this.interestedChannelList = res.data.data
      })
      .catch(err => this.$store.commit('SET_ERROR', err, { root: true }))
  },
  watch: {
    university (newUniversity) {
      // fetch study area list
      axios.post(`${process.env.VUE_APP_BASE_URL  }/StudyAreasofAnUniversity`, {
        univ_id : newUniversity
      })
        .then(res => {
          this.studyAreaList = res.data.data
        })
        .catch(err => this.$store.commit('SET_ERROR', err, { root: true }))
    },
    studyArea (newStudyArea) {
      if (this.university !== '') {
        this.getCourseList(this.university, newStudyArea)
      }
    }
  },
  methods: {
    checkLogin () {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    registerUser () {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return

      if (!/@coursepal.app\s*$/.test(this.email)) {
        this.$vs.notify({
          title: 'Failed',
          text: 'Please use your university email address to signup!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }

      const payload = {
        userDetails: {
          displayName: this.displayName,
          email: this.email,
          university: this.university,
          studyArea: this.studyArea,
          course: this.course,
          studyStatus: this.studyStatus,
          password: this.password,
          confirmPassword: this.confirm_password
        },
        notify: this.$vs.notify
      }
      this.$store.dispatch('auth/registerUser', payload)
    },
    getCourseList (universityId, studyAreaId) {
      axios.post(`${process.env.VUE_APP_BASE_URL  }/getCoursesBasedOnUniAndStudyArea`, {
        univ_id: universityId,
        study_area: studyAreaId
      }).then(res => {
        if (res.data.status) {
          this.courseList = res.data.data
        }
      })
    }
  }
}
</script>
